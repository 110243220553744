import { Ganache, Arbitrum, ArbitrumGoerli, Avalanche, AvalancheFuji, Binance, BinanceTestnet, Cmp, CmpTestnet, Zksync, ZksyncSepoliaTestnet,
    Ethereum, Fantom, FantomTestnet, Sepolia, Mumbai, Optimism, OptimismGoerli, Polygon, Dogechain, DogechainTestnet, Hedera, HederaTestnet,
    LightlinkPhoenix, LightlinkPegasusTestnet, Mantle, MantleTestnet, Base, BaseGoerli, ArtheraTestnet, Arthera, MintTestnet, BerachainBartio,
    ChilizChain, ChilizScovilleTestnet, BobaNetwork, BobaNetworkGoerliTestnet, Cronos, CronosTestnet, KlaytnCypress, KlaytnTestnetBaobab,
    TelosEvm, TelosEvmTestnet, MetalCChain, MetalTahoeCChain, Linea, LineaSepolia, Astar, ConfluxEspace, ConfluxEspaceTestnet, Zetachain, DegenChain, Hychain, Inevm, Shibarium,
    ZetachainAthens3Testnet, ScrollSepoliaTestnet, Scroll, AstarZkevm, Zkatana, TaikoKatlaL2, Zora, ZoraSepoliaTestnet, FormTestnet, BerachainArtio, BlastSepoliaTestnet, BlastBlastmainnet, ShardeumSphinx1X, Rari, RarichainTestnet } from '@thirdweb-dev/chains'
// import { TaikoJolnir } from '@/Helpers/CustomBlockchains'
// import {BlastL2} from "@/Helpers/CustomBlockchains";

export function checkCurrentBlockchain(blockchains, chainId, wallet) {
    const blockchain = blockchains.value[chainId]

    if (!blockchain || wallet.value == false || wallet.value.account == false) {
        return 'wallet'
    } else if (blockchain.chainId != wallet.value.chainId && wallet.value.name == 'metamask') {
        return 'chain'
    } else {
        return true
    }
}

export function getBlockchains() {
    Cmp.name = 'Caduceus'
    CmpTestnet.name = 'Caduceus Testnet'

    // Set CoinGecko Token IDs
    Arbitrum.coingecko = 'arbitrum';
    Avalanche.coingecko = 'avalanche-2';
    Binance.coingecko = 'binancecoin';
    Cmp.coingecko = 'caduceus';
    Dogechain.coingecko = 'dogecoin';
    Ethereum.coingecko = 'ethereum';
    Fantom.coingecko = 'fantom';
    Optimism.coingecko = 'optimism';
    Polygon.coingecko = 'matic-network';
    // ZksyncEra.coingecko = '';
    Hedera.coingecko = 'hedera-hashgraph';
    LightlinkPhoenix.coingecko = 'ethereum'
    Mantle.coingecko = 'mantle';
    Base.coingecko = 'ethereum'
    Linea.coingecko = 'ethereum';
    ChilizChain.coingecko = 'chiliz';
    BobaNetwork.coingecko = 'boba-network';
    Cronos.coingecko = 'crypto-com-chain';
    KlaytnCypress.coingecko = 'klay-token';
    TelosEvm.coingecko = 'telos';
    MetalCChain.coingecko = 'metal-blockchain';
    Astar.coingecko = 'astar';
    AstarZkevm.coingecko = 'ethereum'
    ConfluxEspace.coingecko = 'conflux-token';
    Zetachain.coingecko = 'zetachain'
    Zora.coingecko = 'ethereum'
    // BlastL2.coingecko = 'ethereum'
    BlastBlastmainnet.coingecko = 'ethereum'
    Rari.coingecko = 'ethereum'
    DegenChain.coingecko = 'degen-base'
    Hychain.coingecko = 'hychain'
    Inevm.coingecko = 'injective'
    Shibarium.coingecko = 'bone-shibaswap'
    Scroll.coingecko = 'ethereum'
    Zksync.coingecko = 'ethereum'

    const mainnets = {
        1: Ethereum,
    }
    const testnets = {
        80085: BerachainArtio,
        80084: BerachainBartio,
    }
    // testnets[1337] = Ganache
    // testnets[1337].chainId = 1337
    // testnets[1337].networkId = 1337
    // testnets[1337].rpc = [
    //     'HTTP://127.0.0.1:7545'
    // ];
    // testnets[1337].explorers = [{
    //     "name": "ganache",
    //     "url": "https://www.ganache.com",
    //     "standard": "EIP3091"
    // }]

    return {...mainnets, ...testnets}
}

export function getBlockchain(chainId) {
    const blockchains = getBlockchains()
    return blockchains[chainId]
}
